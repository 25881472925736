// External packages
import * as React from "react"

// Contexts
import { useStore } from "../../context/NewStoreContext"

const translationsObj: Record<string, Record<string, string>> = {
  kr: {
    "Sign up to our newsletter and receive a 10% discount on your next order.":
      "뉴스레터 구독하고 10% 할인코드 발급받으세요.",
  },
}

export const useTranslation = () => {
  const { cart } = useStore()

  const translate = React.useCallback(
    (key: string): string => {
      const translations = translationsObj[cart?.region?.countries?.[0]?.iso_2]

      if (translations) {
        return translations[key] || key
      }
    },
    [cart?.region?.countries?.[0]?.iso_2]
  )

  return translate
}
